import React from 'react';
import cn from 'classnames'
import './preloader.scss';

const Preloader = ({ isNeutralColor = false }) => {
  return (
    <div className={cn('loader', isNeutralColor && 'loader__neutral')} />
  );
};

export default Preloader;
