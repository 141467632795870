import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useRegionValidation } from "../../../hooks/useRegionValidation";
import { EMAIL_INPUT_VALIDATION, REQUIRED_FIELD_VALIDATION } from "../../../utils/validators";
import { useAppDispatch, useAppSelector } from "../../../store";
import { login, selectIsAuthorizing } from "../../../store/auth";
import { FormInput } from "../../../components/Form/FormInput/FormInput";
import { Button } from "../../../components/Button/Button";
import { PasswordInput } from "../../../components/Form/PasswordInput/PasswordInput";
import { LoginData } from "../../../types/Auth";
import { FormHeader } from "../_shared/FormHeader/FormHeader";
import LoginImg from "../../../assets/img/login.jpg";
import { AuthLayout } from "../_shared/AuthLayout/AuthLayout";
import { getMode, selectTheme } from "../../../store/theme";
import { useIp } from "../../../hooks/useIp";
import './signin.scss';

export const SignIn = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ip = useIp();
    const { sign_in } = useAppSelector(selectTheme);
    const isSubmitting = useAppSelector(selectIsAuthorizing);

    const {handleSubmit, control} = useForm<LoginData>({
        reValidateMode: 'onChange',
    });

    const onSubmit = ({ email, password }: LoginData) => {
        dispatch(login({ email, password, ip })).then((res) => {
            if (res && res.meta.requestStatus === 'fulfilled') {
                dispatch(getMode());
                navigate('/cryptocurrency');
            }
        });
    };

    usePageTitle(intl.formatMessage({id: "auth.signIn.title", defaultMessage: "Sign in"}));

    useRegionValidation();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        if (token) {
            try {
                localStorage.setItem('token', token);
                setTimeout(() => {
                    window.location.replace('/cryptocurrency');
                }, 100);
            } catch (error) {
              console.error('ERROR: ', error);
            }
        }
    }, [window.location.search]);

    return (
        <AuthLayout
            src={sign_in || LoginImg}
            pageTitle={<FormattedMessage id="auth.signIn.page_title" defaultMessage="Welcome back!"/>}
        >
            <FormHeader title={<FormattedMessage id="auth.signIn.title" defaultMessage="Sign in"/>}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                    name='email'
                    type='email'
                    autoComplete={'email'}
                    label={<FormattedMessage id="auth.signIn.fieldName.email" defaultMessage="E-mail"/>}
                    control={control}
                    rules={{
                        ...REQUIRED_FIELD_VALIDATION,
                        validate: EMAIL_INPUT_VALIDATION,
                    }}
                />
                <PasswordInput
                    name="password"
                    autoComplete={'current-password'}
                    label={
                        <FormattedMessage
                            id='auth.signIn.fieldName.password'
                            defaultMessage='Password'
                        />
                    }
                    control={control}
                    rules={REQUIRED_FIELD_VALIDATION}
                />
                <div className="_flex _flex-j-e _m-b-50">
                    <Link to="/forgot-password" className="text-link">
                        <FormattedMessage
                            id="auth.signIn.forgotPassword"
                            defaultMessage="Forgot your password?"
                        />
                    </Link>
                </div>
                <Button tag="button" type="submit" className="_w-100" isDisabled={isSubmitting}>
                    <FormattedMessage id="auth.signIn.button" defaultMessage="Sign in"/>
                </Button>
                <div className="_flex _flex-xy-c _m-t-40 _ff-roboto _fz-14">
                    <FormattedMessage
                        id="auth.signIn.singUp.doNotHaveAccount"
                        defaultMessage="Do not have an account yet?"
                    />
                    <Link to="/sign-up" className="_p-l-5 _text-main">
                        <FormattedMessage id="auth.signIn.singUp.btn" defaultMessage="Sign up here"/>
                    </Link>
                </div>
            </form>
        </AuthLayout>
    );
}