import axios from 'axios';
import { COUNTRY_NOT_ALLOWED } from "../constants/errorKeys";
import { addToast } from "../store/toast";
import { store } from "../store";
import { resetAuth } from "../store/auth";
import { setMode } from "../store/theme";

export const DEV_API_URL = 'https://backdevlk.wolf-crm.com'

const api = axios.create({
    baseURL: DEV_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    },
    timeout: 30000,
});

api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        const lang = localStorage.getItem('lang') || 'en';

        config.headers['Authorization'] = 'Bearer ' + token;
        config.headers['X-Localization'] = lang;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    (response: any) => {
        const {status} = response;
        if (status === 200 || status === 201) {
            return response;
        }
    },
    (error) => {
        if (error?.response?.status === 500) {
            store.dispatch(addToast({
                type: 'error',
                message: `${error.response.status} ${error.response.statusText}`,
                timeout: 3000,
            }));
        }
        if (error?.response?.status === 403) {
            if (error.response.data?.message === COUNTRY_NOT_ALLOWED) {
                const userLanguage = localStorage.getItem('lang');
                sessionStorage.setItem(COUNTRY_NOT_ALLOWED, "true");
                localStorage.clear();
                userLanguage && localStorage.setItem('lang', userLanguage);

                const isPublicRoute = ['/sign-up', '/'].includes(window.location.pathname);
                if (!isPublicRoute) {
                    window.location.replace('/');
                }
            }
        }
        if (error?.response?.status === 401) {
            if (store.getState()?.auth?.isAuthorized) {
                localStorage.removeItem('token');
                localStorage.removeItem('theme-mode');
                store.dispatch(resetAuth());
                store.dispatch(setMode('light'));
                store.dispatch(addToast({type: "error", message: "Unauthenticated", timeout: 5000}));
            }
        }
        return Promise.reject(error);
    },
);

export default api;
